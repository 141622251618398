import './App.css';
import {useEffect, useState} from "react";
import {Button, Col, Form, Row, Table, Modal, Input, Card} from "antd";
import Swal from "sweetalert2";
import SlotMachine from "./components/slot-machine";
const moment = require('moment-timezone');

function App() {
    const [nineGridHeight, setNineGridHeight] = useState(0);
    const [chances, setChances] = useState(0);
    const [data, setData] = useState([])
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [modalVisible, setModalVisible] = useState(true)
    const onFinished = () => {
        refreshTable(username, email);
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'sdate',
        },
        {
            title: 'Drawn Numbers',
            dataIndex: 'spinned_result',
        },
    ];

    const refreshTable = (user, email) => {
        const url = 'https://api.wsbbgames.com/app/api/spin/history';
        const data = {
            username: user,
            email: email,
        };

        fetch(url, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                // Add any other necessary headers here
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(response => response.json()) // parses JSON response into native JavaScript objects
            .then(data => {
                if (data.code === 200){
                    //here
                    let datetime = getGMT8Date().toString().split(" ");
                    setData(data.data);
                    let d = data.data[0];
                    if (d !== undefined && d.sdate === datetime[0]){
                        setChances(3 - d.spinned_result.split(",").length);
                    }else{
                        setChances(3);
                    }
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function getGMT8Date() {
        // 获取当前 GMT+8 时间
        const gmt8Date = moment.tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
        return gmt8Date;
    }

    useEffect(() => {
        setNineGridHeight(window.innerHeight - document.getElementById("holdingCard")?.offsetHeight)
    }, [])

    const handleOk = () => {

    }

    const handleCancel = () => {

    }

    const onFinish = (values) => {
        const url = 'https://api.wsbbgames.com/app/api/spin/check';
        const data = {
            username: values.username,
            email: values.email,
        };

        fetch(url, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                // Add any other necessary headers here
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(response => response.json()) // parses JSON response into native JavaScript objects
            .then(data => {
                if (data.code === 200){
                    //here
                    refreshTable(values.username, values.email);
                    setUsername(values.username);
                    setEmail(values.email);
                    setModalVisible(false);
                }else{
                    Swal.fire({
                        title: "Too bad!",
                        text: data.msg,
                        icon: "error"
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const onFinishFailed = (error) => {
        console.error('Error:', error);
    }

    return (
        <div className="App" style={{maxWidth: "500px", margin: "auto"}}>
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Card id="holdingCard" style={{height: "100%", background: "transparent", border: "none"}}>
                        <SlotMachine
                            username={username}
                            email={email}
                            onFinished={() => onFinished()}
                        />
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Card style={{height: nineGridHeight, border: "0px", background: "linear-gradient(180deg, #60411A, #1D1304)", borderRadius: "20px 20px 0 0 "}}>
                        <h2 style={{marginTop: "0px", color: "#E4CC91"}}>Draw History</h2>
                        <div style={{overflow: "auto"}}>
                            <Table style={{border: "0px", borderRadius: "12px 12px 0 0"}} dataSource={data} columns={columns} pagination={false} scroll={{ y: 300 }} />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Modal title="" footer={null} closable={false} open={modalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Username" />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input type="email" placeholder="Enter Email" />
                    </Form.Item>
                    <Form.Item style={{textAlign: "center"}}>
                        <Button type="primary" htmlType="submit" block>
                            Confirm
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default App;
